<template>
  <div class="container">
    <b-row>
      <b-col lg="8">
        <article class="post-area single-blog">
          <div class="about-me">
            <h1>O projektu</h1>
            <div class="my-4">
              <p>
                Projekt 'Zapisi spomina' se izvaja v okviru razpisa 'Spodbude za rešitve: Dolgoživa družba', ki ga
                sofinancirata Stičišče NVO Istre in Krasa – Iskra in MJU iz Sklada za NVO.
              </p>
              <img
                  :src="require('@/assets/images/sofinancerji_logoti.jpg')"
                  alt=""
                  class=""
                  style="min-width: 100%; border-radius: 20px; margin: 0px auto"
              />

              <h3>Namen projekta</h3>
              <p>Namen projekta je s pomočjo razvite aplikacije okrepiti vključenost starejših v družbo in vplivati na
                pozitivno podobo starejših kot tistih, ki hranijo spomin, ustno izročilo. V aplikaciji se beležijo in
                objavljajo védenja, pričevanja, spomini starejših na najrazličnejše vidike življenja v nekem prejšnjem
                obdobju. Projekt cilja na to, da postane ravno ta dediščina, to ustno izročilo vezivo za krepitev
                medgeneracijskih povezav.</p>

              <h4>Projekt omogoča:</h4>
              <p>
              <ul>
                <li>družbeno-koristno uporabo IKT in digitalno opismenjevanje starejših;</li>
                <li>krepitev sodelovanja in prenosa znanja, izkušenj med generacijami;</li>
                <li>pozitiven vpliv na razumevanje družbene vloge starejših (starejši kot nosilci in izpričevalci
                  spomina) in preprečevanje staromrzništva;
                </li>
                <li>dvig kakovosti življenja in dostojanstveno staranje z vrnitvijo občutka prispevanja družbi,
                  koristnosti;
                </li>
                <li>prostovoljsko udejstvovanje starejših s pričevanji o preteklosti;</li>
                <li>krepitev zavedanja med starejšimi in v širši javnosti o vrednosti in o pomenu ustnega izročila</li>
              </ul>
              </p>

            </div>
          </div>

          <div class="w-100 text-center" style="margin: 0px auto">
            <img
                :src="require('@/assets/images/zs_theme.jpg')"
                alt=""
                class=""
                style="min-width: 100%; border-radius: 20px; margin: 0px auto"
            />
          </div>

          <div class="my-4">

            <h3>Kaj o projektu pravijo konzorcijski partnerji?</h3>

            <img
                id="zavod-dobra-pot" name="zavod-dobra-pot"
                :src="require('@/assets/images/logo_zdp.jpg')"
                alt=""
                class="mt-2"
                style="min-width: 100%; border-radius: 20px; margin: 0px auto"
            />

            <h4 class="mt-3">Zavod Dobra pot</h4>
            <blockquote style="font-style: italic">
              <p>Aplikacija Zapisi spomina je namenjena ljudem. Namenjena je temu, da pokaže na vrednost ustnega
                izročila
                in da se ob obujanju, pripovedovanju in zapisovanju spominov okrepijo medgeneracijske povezave med
                ljudmi.</p>
              <p>Premalo se zavedamo, kako dragoceni so spomini, izkušnje, vedenja, spoznanja čisto vsakega izmed nas.
                Čisto vsak s svojim življenjem piše zgodbo, ki tvori tkivo človeštva, zgodbe starejših pa so tiste, ki
                so
                najdaljše. Te zgodbe se ne smejo pozabiti.</p>
              <p>V mnogih pogledih nam lahko spomini ljudi, ki so bili priča nekemu drugemu času, drugačnemu načinu
                življenja od tega, kar poznamo danes, služijo kot zgled, kot opozorilo, kot smerokaz za prihodnost. Zato
                jih je vredno zapisati, saj si vsi želimo, da se prihodnje generacije učijo iz preteklosti, stopijo na
                ramena prednikov in zrasejo še dlje, še više.</p>
              <p>Aplikacija omogoča, da se spomini in pričevanja iz preteklosti zapišejo na način, primeren sodobnemu
                času, in se obenem arhivirajo v digitalni obliki. Omogoča tudi, da v zakladnico skupnega spomina
                prispeva
                čisto vsak.</p>
              <p>Iskreno upamo, da bodo vsebine, ki jih bomo zapisovali, med uporabniki odpirale pogovore, prinašale
                spoznanja, globino, toplino, navdih, smisel in tako še dolgo služile naši skupni rasti.</p>
            </blockquote>

            <img
                id="javni-park-skocjanske-jame" name="javni-park-skocjanske-jame"
                :src="require('@/assets/images/logo_psj.jpg')"
                alt=""
                class="mt-5"
                style="min-width: 100%; border-radius: 20px; margin: 0px auto"
            />

            <h4 class="mt-3">Javni zavod Park Škocjanske Jame</h4>
            <blockquote style="font-style: italic">
              <p>Spomin je v samem temelju izročila tako posameznika kot take ali drugačne skupnosti. Še več,
                zgodovinski
                spomin se enači z izročilom oziroma dediščino, ki vsakokratno sedanjost posameznika ali skupnosti
                povezuje
                s prejšnjimi obdobji.</p>
              <p>Javni zavod Park Škocjanske jame, Slovenija je bil med drugim ustanovljen za zagotavljanje varstva
                kulturne dediščine na območju Regijskega parka Škocjanske jame, kot upravljavec UNESCO biosfernega
                območja
                Kras in porečje Reke, pa je skupaj s prebivalci idr. lokalnimi akterji zavezan k ohranjanju kulturne
                raznovrstnosti. Zavod Dobra pot je eden od teh lokalnih akterjev s katerim z veseljem sodelujemo z
                željo,
                da s skupnimi močmi vsakič znova ustvarjamo možnosti in priložnosti za udejanjanje našega
                poslanstva.</p>
            </blockquote>


            <img
                id="slovenski-etnografski-muzej" name="slovenski-etnografski-muzej"
                :src="require('@/assets/images/logo_sem.jpg')"
                alt=""
                class="mt-5"
                style="min-width: 100%; border-radius: 20px; margin: 0px auto"
            />

            <h4 class="mt-3">Slovenski etnografski muzej</h4>
            <blockquote style="font-style: italic">
              <p>Slovenski etnografski muzej (SEM) je muzej "o ljudeh, za ljudi", muzej kulturnih istovetnosti, prostor
                dialoga med preteklim in sedanjim, med svojo in drugimi kulturami, med naravo in civilizacijo. Že vrsto
                let posveča veliko pozornost razvoju partnerstev in raznim oblikam sodelovanj pri spodbujanju
                obravnavanja
                etnoloških in antropoloških tem s področja kulture in njene interpretacije v sodobni čas.</p>
              <p>V tem kontekstu je začel razvijati uspešno sodelovanje z Zavodom Dobra pot. Najprej se je leta 2019 kot
                aktivni udeleženec priključil k projektu Trade Knowledge, znotraj katerega se je kustodinja SEM
                usposabljala na kulturnih delavnicah »Youth + Culture How To« v Nurmesu na Finskem. V letu 2021 pa je
                SEM
                postal partner projekta Zapisi spomina.</p>
              <p>SEM podpira cilj projekta, ki je okrepiti vključenost starejših v družbo – kot pomembnih nosilcev znanj
                in veščin, kot nosilcev živega izročila. Teme projekta se tesno povezujejo s tistimi, ki jih ima SEM kot
                Koordinator varstva nesnovne kulturne dediščine že vključene v svojo kategorizacijo. Zato bo muzej v
                letu
                2021 projekt promoviral kot primer dobre prakse, ko bo aplikacija zaživela, pa bo uporabnikom omogočal
                obisk muzeja po znižani ceni.</p>
            </blockquote>
          </div>
        </article>
      </b-col>
      <b-col lg="4">
        <Sidebar></Sidebar>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// @ is an alias to /src
// import firebase from "firebase";
// import {db} from "../../main";

import Sidebar from "@/components/Sidebar";

export default {
  name: "About",
  components: {
    Sidebar
  },
  data() {
    return {
      users: []
    };
  },
  metaInfo() {
    return {
      title: 'O projektu'
    }
  },
  beforeCreate() {
    const selectedLang = localStorage.getItem('zapisi_spomina_language');
    if(selectedLang !== null) {
      if(selectedLang === 'EN' && !this.$route.fullPath.includes('/en')) {
        this.$router.push({name: 'EN_' + this.$route.name}, this.$route.params);
      } else if(selectedLang === 'SI' && this.$route.fullPath.includes('/en')) {
        this.$router.push({name: this.$route.name.split('EN_')[1]}, this.$route.params);
      }
    }
  },
  mounted() {
  },
  methods: {}
};
</script>
